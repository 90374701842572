import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import { useRoomPageData } from "../hooks/useRoomsData"
import PageHeader from "../components/pageHeader"
import RoomSection from "../components/rooms/roomSection"
import { withPreview } from "gatsby-source-prismic"

const Rooms = ({ data: previewData }) => {
  const { bookData, roomSections, headerData, uid } =
    useRoomPageData(previewData)

  return (
    <Layout bookBannerData={bookData}>
      <SEO title={uid} />
      <PageHeader
        {...headerData}
        navItems={["suites", "lanai-suites", "townhouse"]}
      />
      {roomSections.map((roomSection, i) => (
        <RoomSection
          key={i}
          id={roomSection.primary.room_category}
          {...roomSection.primary}
          rooms={roomSection.rooms}
        />
      ))}
    </Layout>
  )
}

export default withPreview(Rooms)
