import { graphql, useStaticQuery } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

export const useRoomPageData = previewData => {
  const staticData = useStaticQuery(graphql`
    query {
      prismicRoomsPage {
        _previewable
        uid
        data {
          room_item_icon {
            fluid {
              aspectRatio
              sizes
              src
              srcSet
            }
            fixed(width: 15) {
              base64
              height
              width
              src
              srcSet
            }
          }
          body {
            ... on PrismicRoomsPageBodyRoomTypeHeading {
              slice_type
              primary {
                room_category
                image {
                  alt
                  fluid {
                    aspectRatio
                    sizes
                    src
                    srcSet
                  }
                }
                text {
                  raw
                  text
                  html
                }
              }
            }
            ... on PrismicRoomsPageBodyHeader {
              primary {
                title {
                  html
                  text
                }
                text {
                  text
                }
                tagline {
                  text
                }
              }
              slice_type
            }
            ... on PrismicRoomsPageBodyBook {
              primary {
                button_text
                link {
                  url
                  link_type
                }
                text {
                  raw
                  text
                }
              }
              slice_type
            }
          }
        }
      }
      allPrismicRoom {
        nodes {
          uid
          data {
            room_category
            room_type
            title {
              raw
              text
              html
            }
            text {
              raw
              text
              html
            }
            summary_features {
              feature_icon {
                fluid {
                  aspectRatio
                  sizes
                  src
                  srcSet
                }
                fixed(width: 36) {
                  base64
                  height
                  width
                  src
                  srcSet
                }
                alt
              }
              feature_text {
                text
              }
            }
            gallery {
              big_image {
                fluid {
                  aspectRatio
                  sizes
                  src
                  srcSet
                }
                fixed(width: 400) {
                  base64
                  width
                  src
                  srcSet
                }
                alt
              }
            }
          }
        }
      }
    }
  `)
  const mergedData: any = mergePrismicPreviewData({
    staticData,
    previewData,
    strategy: "rootReplaceOrInsert",
  })
  const { uid, data } = mergedData.prismicRoomsPage
  const roomSections = data.body.filter(
    section => section.slice_type === "room_type_heading"
  )

  let suites = []
  let lanaiSuites = []
  let townhouse = []
  let headerData
  let bookData
  data.body.map(section => {
    if (section.slice_type === "header") {
      headerData = section.primary
    } else if (section.slice_type === "book") {
      bookData = section.primary
    }
  })
  mergedData.allPrismicRoom.nodes.forEach(room => {
    room.link_icon = data.room_item_icon
    if (room.data.room_category.toLowerCase() === "suites") {
      suites.push(room)
    }
    if (room.data.room_category.toLowerCase() === "lanai suites") {
      lanaiSuites.push(room)
    }
    if (room.data.room_category.toLowerCase() === "townhouse") {
      townhouse.push(room)
    }
  })
  roomSections.map(room => {
    if (room.primary.room_category.toLowerCase() === "suites") {
      room.rooms = suites
    }
    if (room.primary.room_category.toLowerCase() === "lanai suites") {
      room.rooms = lanaiSuites
    }
    if (room.primary.room_category.toLowerCase() === "townhouse") {
      room.rooms = townhouse
    }
  })

  return {
    uid,
    headerData,
    bookData,
    roomSections,
  }
}
