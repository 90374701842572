import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

import { SectionTitle } from "../atoms"
import RoomItem from "./roomItem"

import getSortedRooms from "../../utils/getSortedRooms"

const RoomSection = ({ image, text, room_category, rooms }) => {
  const containerId = room_category.toLowerCase().replace(" ", "-")

  return (
    <Container id={containerId}>
      <SectionTitle>{room_category}</SectionTitle>

      <ImageContainer fluid={image.fluid} alt={image.alt}>
        <div dangerouslySetInnerHTML={{ __html: text.html }} />
      </ImageContainer>

      <RoomItemsContainer>
        {getSortedRooms(room_category, rooms).map((room, i) => {
          return room ? (
            <RoomItem
              key={i}
              {...room.data}
              icon={room.link_icon}
              link={room.uid}
            />
          ) : null
        })}
      </RoomItemsContainer>
    </Container>
  )
}

export default RoomSection

const Container = styled.div`
  width: 80%;
  margin: 6rem auto;

  @media (max-width: 880px) {
    width: 95%;
  }
`

const ImageContainer = styled(BackgroundImage)`
  height: 400px;
  display: flex;
  align-items: center;
  margin: 4rem 0;

  min-height: 200px;
  max-height: 460px;
  p {
    max-width: 80%;
    margin: 0 auto;
    padding: 16px;
    color: var(--primary-white);
    font-size: 3rem;
    font-family: "Canela Light", sans-serif;
    @media (max-width: 950px) {
      font-size: 2rem;
    }
    @media (max-width: 550px) {
      font-size: 1.5rem;
    }
  }
  @media (max-width: 950px) {
    height: 300px;
  }
  @media (max-width: 550px) {
    height: 250px;
  }
`

const RoomItemsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  justify-content: space-between;
  grid-gap: 40px;
  @media (max-width: 740px) {
    justify-content: center;
  }
`
