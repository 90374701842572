import React from "react"
import styled from "styled-components"
import { TextPreview } from "../atoms/text"
import { Link } from "gatsby"
import Img from "gatsby-image"

const RoomItem = props => {
  const { gallery, summary_features, text, title, icon, link } = props

  return (
    <StyledLink to={"/rooms/" + link}>
      <Container>
        <ImageContainer>
          <Img
            fixed={gallery[0].big_image.fixed}
            alt={gallery[0].big_image.alt || "room"}
          />
        </ImageContainer>
        <TextContainer>
          <RoomLinkContainer>
            <Title>{title.text}</Title>
            <Icon>
              <Img fixed={icon.fixed} alt="go to" />
            </Icon>
          </RoomLinkContainer>
          <TextPreview>{text.text}</TextPreview>
        </TextContainer>
        <FeaturesContainer>
          {summary_features.map((feature, i) => (
            <FeatureContainer key={i}>
              <FeatureIcon>
                <Img fixed={feature.feature_icon.fixed} alt="feature" />
              </FeatureIcon>
              <IconText>{feature.feature_text.text}</IconText>
            </FeatureContainer>
          ))}
        </FeaturesContainer>
      </Container>
    </StyledLink>
  )
}

export default RoomItem

const StyledLink = styled(Link)`
  text-decoration: none;
`

const Container = styled.div`
  justify-self: center;
  width: 100%;

  :hover {
    > div:nth-child(2) > div {
      div:last-child {
        left: 0;
      }
    }
    > div:nth-child(1) {
      .gatsby-image-wrapper {
        transform: scale(1.1);
        overflow: hidden;
      }
    }
  }
`
const ImageContainer = styled.div`
  height: 250px;
  overflow: hidden;
  .gatsby-image-wrapper {
    transition: all 0.3s ease;
    height: 100%;
    width: 100%;
  }
`
const TextContainer = styled.div``
const RoomLinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
`
const Title = styled.h2`
  font-family: "Moderat Regular", sans-serif;
  color: var(--primary-text);
  margin: 1rem 0;
`

const FeaturesContainer = styled.div`
  display: grid;
  grid-gap: 16px 0;
  div:last-child {
    border: none;
  }
`
const FeatureContainer = styled.div`
  display: grid;
  grid-template-columns: 70px auto;
  align-items: center;
  grid-gap: 0 16px;
  padding: 16px 0;
  border-bottom: 1px solid var(--primary-text);
`
const Icon = styled.div`
  .gatsby-image-wrapper {
    top: 1px;
    width: 16px;
    left: -5px;
    transition: all 0.3s ease;
  }
`
const FeatureIcon = styled.div`
  width: 40px;
  justify-self: center;
`
const IconText = styled.p`
  font-family: "Moderat Regular", sans-serif;
  color: var(--primary-text);
`
